<template>
  <div>
    <div
    v-if="$attrs.value.shopee_product_id"
    >
      <h4>เชื่อมต่อแล้วกับ : {{ $attrs.value.salechannel }}</h4>
      <shopee-product-info
      :value="$attrs.value"
      :parentData="parentData"
      class="mt-4"
      ></shopee-product-info>
    </div>
    <div v-else-if="shopeeList">
      <v-row>
        <v-col>
          <v-select
            v-model="$attrs.value.shopee_salechannel_id"
            :items="shopeeList"
            label="เลือกช่องทาง Shopee"
            item-text="name"
            item-value="id"
            outlined
            dense
            :rules="[validators.required]"
            hide-details="auto"
            clearable
          >
          </v-select>
        </v-col>
      </v-row>
      <shopee-product-info
      :value="$attrs.value"
      :parentData="parentData"
      class="mt-4"
      v-if="$attrs.value.shopee_salechannel_id"
      ></shopee-product-info>
    </div>
    <div v-else>
      <v-btn
      color="#FF6600"
      class="white--text mt-2"
      @click="getShopeeSaleChannels"
      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
      :loading="loading"
      >กดที่นี่เพื่อเลือกช่องทางการขาย Shopee</v-btn>
    </div>
  </div>
</template>
<script>
import errorParser from '@/plugins/errorparser'
import ShopeeProductInfo from './ShopeeProductInfo.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
export default {
  props: ['parentData'],
  data() {
    return {
      loading: false,
      shopeeList: null,
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  methods: {
    async getShopeeSaleChannels() {
      try {
        this.loading = true

        const response = await asyncGet('/product/shopeeList')

        this.shopeeList = response
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.loading = false
    },
  },
  components: {
    ShopeeProductInfo,
  },
}
</script>
