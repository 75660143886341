<template>
    <div>
        <v-simple-table style="width: 100%">
          <template v-slot:default>
            <thead>
                <tr>
                    <th
                        class="text-center"
                        style="width: 60%;"
                    >ขนส่ง Shopee</th>
                    <th class="text-center">การตั้งค่า</th>
                </tr>
              </thead>
              <tbody>
                <!--
                <tr
                  v-for="(logistic) in $attrs.value"
                  :key="'tr_' + logistic.logistic_id"
                >
                  <td>
                    {{ logistic.logistic_name }}
                  </td>
                  <td>
                    <v-switch
                      v-model="logistic.enabled"
                      inset
                    ></v-switch>
                  </td>
                </tr>
                -->
                <tr
                  v-for="(logistic) in $attrs.value"
                  :key="'tr_' + logistic.logistic_id"
                >
                  <td>
                    <div class="d-flex align-center">
                      <div>
                        {{ logistic.logistic_name }}
                      </div>
                      <v-chip-group class="ml-4">
                        <v-chip
                          v-if="logistic.is_free"
                          color="info"
                        >
                          ลูกค้าส่งฟรี
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-center justify-center">
                      <v-btn
                      icon
                      @click="showEditModal(logistic)"
                      ><v-icon>{{ icons.mdiPencil }}</v-icon></v-btn>
                      <v-switch
                        v-model="logistic.enabled"
                        inset
                        :rules="getSwitchRules(logistic)"
                      ></v-switch>
                    </div>
                  </td>
                </tr>
              </tbody>
          </template>
        </v-simple-table>
        <v-dialog
        v-model="editModal.isShow"
        width="500"
        persistent
        >
          <v-card v-if="editModal.logistic">
            <v-card-title>
              ขนส่ง : {{  editModal.logistic.logistic_name }}
            </v-card-title>

            <v-card-text>
              <v-select
              label="* ขนาด"
              v-if="editModal.logistic.fee_type == 'SIZE_SELECTION'"
              :items="editModal.logistic.size_list"
              v-model="editModal.logistic.size_id"
              item-text="name"
              item-value="size_id"
              dense
              outlined
              ></v-select>

              <!--
              SIZE_SELECTION
              SIZE_INPUT
              FIXED_DEFAULT_PRICE
              CUSTOM_PRICE
              SELLER_LOGISTICS
              -->

              <v-text-field
                v-else-if="editModal.logistic.fee_type == 'CUSTOM_PRICE'"
                v-model.number="editModal.logistic.shipping_fee"
                outlined
                label="* ค่าส่ง"
                placeholder="0.00"
                hide-details="auto"
                dense
                type="number"
                suffix="฿"
              ></v-text-field>

              <v-checkbox
                v-model="editModal.logistic.is_free"
                label="ฉันจะออกค่าขนส่งให้ลูกค้า"
              ></v-checkbox>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" class="ml-auto" @click="editModal.isShow = false">ตกลง</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mdiPencil } from '@mdi/js'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
  dimensionValidator,
  weightMinValidator,
  weightMaxValidator,
} from '@core/utils/validation'
export default {
  props: ['logisticChannelList', 'parentData'],
  data() {
    return {
      editModal: {
        isShow: false,
        logistic: null,
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
        dimensionValidator,
        weightMinValidator,
        weightMaxValidator,
      },
      icons: {
        mdiPencil,
      },
    }
  },
  methods: {
    showEditModal(logistic) {
      this.editModal.isShow = true
      this.editModal.logistic = logistic
    },
    getSwitchRules({ shipping_fee, size_id, fee_type, enabled, item_max_dimension, weight_limit }) {
      if (!enabled) return []

      const { package_height, package_width, package_length, package_weight } = this.parentData

      const retObj = []

      if (item_max_dimension) {
        retObj.push(
          this.validators.dimensionValidator(
            {
              height: package_height,
              width: package_width,
              length: package_length,
            },
            {
              maxHeight: item_max_dimension.height,
              maxwidth: item_max_dimension.width,
              maxLength: item_max_dimension.length,
              maxSum: item_max_dimension.dimension_sum,
            },
          ),
        )
      }

      if (weight_limit) {
        retObj.push(this.validators.weightMinValidator(package_weight, weight_limit.item_min_weight))
        retObj.push(this.validators.weightMaxValidator(package_weight, weight_limit.item_max_weight))
      }

      if (fee_type == 'SIZE_SELECTION') {
        retObj.push(this.validators.required(size_id))
      } else if (fee_type == 'CUSTOM_PRICE') {
        retObj.push(this.validators.required(shipping_fee))
      }

      return retObj
    },
  },
}
</script>
