<template>
    <div>
        <v-simple-table style="width: 100%">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th
                            class="text-center"
                        >รูป</th>
                        <th
                            v-for="(attribute, index) in attributes"
                            :key="'header_' + index"
                            width="100px"
                            class="text-center"
                        >{{ attribute.name }}</th>
                        <th class="text-center">ราคาใน shopee</th>
                    </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, valueIndex) in $attrs.value"
                    :key="'tr_' + valueIndex"
                  >
                    <td>
                      <v-img
                      v-if="value.image"
                      :src="value.image.url" style="max-width: 100px; max-height: 100px"
                      ></v-img>
                    </td>
                    <template
                      v-for="(optionValue, optionIndex) in value.options"
                    >
                      <td
                        v-if="value.options.length == 1
                        || (value.options.length > 1 && optionIndex != 0)
                        || (value.options.length > 1 && optionIndex == 0 && valueIndex % attributes[1].terms.length == 0)"
                        :rowspan="attributes.length > 1 && optionIndex == 0 ? attributes[1].terms.length : 1"
                      >{{ optionValue.term }}</td>
                    </template>
                    <td>
                      <v-text-field
                        v-model.number="value.defaultPrice"
                        outlined
                        label="*ราคาปกติ"
                        placeholder="0.00"
                        :rules="[validators.required, validators.numberValidator]"
                        hide-details="auto"
                        dense
                        type="number"
                        suffix="฿"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
export default {
  props: {
    attributes: {
      type: Array,
      required: true,
    },
    size: {
      type: Number,
      default: 2048,
    },
    maxImages: Number,
    minWidth: Number,
    minHeight: Number,
    maxWidth: Number,
    maxHeight: Number,
    ratio: Number,
  },
  data() {
    return {
      url: process.env.VUE_APP_ROOT_API,
      filters: {
        defaultPrice: null,
        discountedPrice: null,
        defaultCost: null,
        sku: null,
        barCode: null,
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
}
</script>
