<template>
  <div>
      <v-row>
        <v-col>
          <v-text-field
            v-model="$attrs.value.name"
            outlined
            label="*ชื่อสินค้าใน shopee"
            :rules="[validators.required, validators.maxLengthValidator($attrs.value.name, 250)]"
            hide-details="auto"
            dense
            counter="250"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea

            v-model="$attrs.value.description"
            outlined
            label="* คำอธิบายใน shopee"
            :rules="[validators.required, validators.maxLengthValidator($attrs.value.description, 2000)]"
            hide-details="auto"
            dense
            counter="2000"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-autocomplete
            :items="categoryList"
            label="* เลือกหมวดหมู่ ของ shopee"
            outlined
            v-model="$attrs.value.category_id"
            item-text="display_category_name"
            item-value="category_id"
            dense
            :rules="[validators.required]"
            :disabled="categoryLoading || brandLoading"
            :loading="categoryLoading"
            clearable
            >
            </v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            :items="brandList"
            label="* เลือกแบรนด์ ของ shopee"
            outlined
            v-model="$attrs.value.brand"
            item-text="display_brand_name"
            item-value="brand_id"
            dense
            :rules="[validators.required]"
            :disabled="brandLoading"
            :loading="brandLoading"
            clearable
            return-object
            >
            </v-autocomplete>
        </v-col>
      </v-row>

      <!-- Variable Product Section -->
      <v-row v-if="parentData.isVariable">
        <v-col cols="12">
          <shopee-model-table :attributes="parentData.attributes" v-model="$attrs.value.variations"></shopee-model-table>
        </v-col>
      </v-row>
      <!-- Simple Product Section -->
      <v-row v-else>
        <v-col>
          <v-text-field
            v-model.number="$attrs.value.original_price"
            outlined
            label="*ราคาปกติใน shopee"
            placeholder="0.00"
            :rules="[validators.required, validators.numberValidator]"
            hide-details="auto"
            dense
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Attribute Section -->
      <!--
      <template v-if="$attrs.value.attribute_list">
        <v-row v-for="(attribute, attribute_index) in $attrs.value.attribute_list">
          <v-col>
            <v-text-field
              v-model="attribute.value"
              outlined
              label="*ราคาปกติ"
              placeholder="0.00"
              :rules="[validators.required, validators.numberValidator]"
              hide-details="auto"
              dense
              type="number"
              suffix="฿"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      -->

      <v-row>
        <v-col>
          <shopee-logistic-table
          :logisticChannelList="logisticChannelList"
          v-model="$attrs.value.logistic_info"
          :parentData="parentData"
          ></shopee-logistic-table>
        </v-col>
      </v-row>
  </div>
</template>
<script>
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import ShopeeModelTable from './ShopeeModelTable.vue'
import ShopeeLogisticTable from './ShopeeLogisticTable.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
export default {
  props: ['parentData'],
  data() {
    return {
      brandList: [],
      categoryList: [],
      logisticChannelList: [],
      brandLoading: false,
      categoryLoading: false,
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  watch: {
    '$attrs.value.shopee_salechannel_id': function (value, oldValue) {
      this.$attrs.value.category_id = null
      this.$attrs.value.brand_id = null

      if (!value) {
        this.categoryList = []
        this.brandList = []
        return
      }

      this.getCategoryList()
      this.getLogisticChannelList()
    },
    '$attrs.value.category_id': function (value, oldValue) {
      if (!value) {
        this.brandList = []
        return
      }

      this.getBrandList()
    },
  },
  mounted() {
    if (!this.$attrs.value.original_price && !this.$attrs.isVariable)
      this.$attrs.value.original_price = this.parentData.defaultPrice

    if (!this.$attrs.value.name) this.$attrs.value.name = this.parentData.name

    if (!this.$attrs.value.variations)
      this.$attrs.value.variations = JSON.parse(JSON.stringify(this.parentData.variations))

    this.getCategoryList()
    this.getLogisticChannelList()
  },
  methods: {
    async getLogisticChannelList() {
      try {
        this.categoryLoading = true

        const response = await asyncGet('/product/' + this.$attrs.value.shopee_salechannel_id + '/shopee/logistic')

        this.logisticChannelList = response

        const logistic_info = this.$attrs.value.logistic_info

        this.logisticChannelList.forEach(item => {
          const info = logistic_info.find(element => element.logistic_id == item.logistics_channel_id)

          if (!info) {
            logistic_info.push({
              ...item,
              logistic_id: item.logistics_channel_id,
              logistic_name: item.logistics_channel_name,
              enabled: false,
              is_free: false,
            })
          } else {
            Object.assign(info, {
              ...item,
              ...info,
            })
          }
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.categoryLoading = false
    },
    async getCategoryList() {
      try {
        this.categoryLoading = true

        const response = await asyncGet('/product/' + this.$attrs.value.shopee_salechannel_id + '/shopee/categories')

        this.categoryList = this.getLeafCategories(response)

        this.getBrandList()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.categoryLoading = false
    },
    async getBrandList() {
      if (!this.$attrs.value.category_id) return

      try {
        this.brandLoading = true

        const response = await asyncGet(
          '/product/' +
            this.$attrs.value.shopee_salechannel_id +
            '/shopee/' +
            this.$attrs.value.category_id +
            '/brands',
        )

        this.brandList = response
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.brandLoading = false
    },
    getLeafCategories(categories) {
      const idToCategory = {}

      // Create a lookup table for quick access
      categories.forEach(category => {
        idToCategory[category.category_id] = category
      })

      function getFullDisplayName(category) {
        let name = category.display_category_name
        let parentId = category.parent_category_id

        while (parentId !== 0 && idToCategory[parentId]) {
          const parent = idToCategory[parentId]
          name = `${parent.display_category_name} > ${name}`
          parentId = parent.parent_category_id
        }

        return name
      }

      return categories
        .filter(category => !category.has_children) // Keep only leaf categories
        .map(category => ({
          ...category,
          display_category_name: getFullDisplayName(category), // Update display name
        }))
    },
  },
  components: {
    ShopeeModelTable,
    ShopeeLogisticTable,
  },
}
</script>
